<template>
    <div :class="footerSectionClasses">
        <div class="footer-section__manage">
            <div class="progress-section">
                <circular-progress-bar
                    :key="getRefreshed"
                    unique-ref="refresh-progress"
                    :used="getRefreshed"
                    :limit="getUserDataProp('refreshLimit')"
                    :color="fill.green"
                    :message="$t('cycle-refreshes-user', { cycle })"
                />
                <circular-progress-bar
                    :key="getDailyAddedKey"
                    unique-ref="daily-added-progress"
                    :used="getDailyAdded.used"
                    :limit="getDailyAdded.limit"
                    :color="fill.yellow"
                    :message="$t('cycle-kw-added', { cycle })"
                />
                <circular-progress-bar
                    :key="getUsedKeywordsKey"
                    unique-ref="used-keywords-progress"
                    :used="getUsedKeywords"
                    :limit="getPackageKeywords"
                    :color="fill.blue"
                    :message="$t('kw-used-on-plan')"
                />
            </div>

            <p v-if="showResetButton" class="reset-limit" @click="showResetRefreshModal" v-html="$t('reset-on-demand-refresh-limit-with-price', { price })" />

            <div class="simple-footer-wrap">
                <kw-button
                    v-if="!isViewOnlyUser && !getSettingIsFetching"
                    :button-text="$t('add_project')"
                    button-type="primary"
                    :button-disabled="isAddProjectDisabled"
                    :class="addProjectButtonClasses"
                    data-cy="add-project-button"
                    prepend-icon-class="kw kw-plus"
                    @click="openAddProject"
                    @prepend-icon-mouseover="
                        tooltip({
                            text: $t('account-restricted-tooltip'),
                            event: $event,
                            disabled: !isAddProjectDisabled,
                        })
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import CircularProgressBar from './CircularProgressBar.vue';
import ResetOnDemandRefreshLimitModal from '@/components/modals/ResetOnDemandRefreshLimitModal';

export default {
    name: 'FooterSection',
    components: {
        'circular-progress-bar': CircularProgressBar,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        openAddProject: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            fill: {
                green: { color: '#41a63d' },
                yellow: { color: '#fed168' },
                blue: { color: '#0074d9' },
            },
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentPlan',
            'getPackageKeywords',
            'getRefreshed',
            'getSettingIsFetching',
            'getUsedKeywords',
            'getUserDataProp',
            'isViewOnlyUser',
            'isAddonRestricted',
            'isAddonUser',
            'getDailyAdded',
            'getIsFreePlan',
            'isAdminOrMasterUser',
            'getTrialPeriod'
        ]),
        isInTrial(){
            const subscription = this.getCurrentPlan.subscription;
            const isExpired = new Date() > new Date(subscription.account_expire_at);
            return this.getTrialPeriod && !isExpired;
        },
        showResetButton(){
            return !this.getIsFreePlan && this.isAdminOrMasterUser && !this.isInTrial;
        },
        footerSectionClasses(){
            return {
                'footer-section': true,
                'free-user': !this.showResetButton
            }
        },
        isAddProjectDisabled() {
            return (
                (this.isAddonUser && this.isAddonRestricted) ||
                this.isViewOnlyUser
            );
        },
        addProjectButtonClasses() {
            return {
                'footer-actions__add-project': true,
                'button-primary_pseudo-disabled':
                    this.getCurrentPlan.expiredStatus === 'expired',
            };
        },
        cycle(){
            return this.getCurrentPlan?.subscription.cycle === 'weekly' ? 
                this.$t('common.weekly') : 
                this.$t('common.daily');
        },
        price(){
            if(this.getCurrentPlan?.subscription) {
                const { billing_cycle, stripe_plan_amount } = this.getCurrentPlan.subscription;
                const amount = Number(stripe_plan_amount.replace(/,/g, ''))
                const currentPrice = billing_cycle === "monthly" ? amount : amount / 12;

                return Math.ceil(currentPrice / 30.42);
            }
            return null;
        },
        getDailyAddedKey(){
            return `daily_added_${this.getDailyAdded.limit}`;
        },
        getUsedKeywordsKey(){
            return `daily_used_${this.getPackageKeywords}`;
        }
    },
    methods: {
        showResetRefreshModal() {
            this.$modal.show(
                ResetOnDemandRefreshLimitModal,
                { 
                    price: this.price,
                    cycle: this.getCurrentPlan.subscription.cycle,
                    limit: this.getCurrentPlan.refreshes.limit
                },
                {
                    clickToClose: false,
                    width: 514,
                    height: 'auto',
                    classes: 'v--modal center-modal-popup not-rounded',
                },
            );
        },
    },
    mounted() {
        if(this.$route.query?.showResetPrompt == '1'){
            this.showResetRefreshModal();
            this.$router.replace({showResetPrompt: null});
        }
    }
};
</script>

<style lang="scss">
.footer-actions__buttons {
    align-content: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-height: 62px;
    padding-left: 8px;
    align-items: center;
}

.footer-section {
    color: kw-color(kw-blue, 1);
    padding: 5px 18px 0 0;
    &.free-user {
        padding-top: 20px;
    }
    .archived-projects {
        max-width: 49%;
        width: 100%;
    }

    .simple-footer-wrap {
        .footer-actions__add-project {
            width: 100%;
            justify-content: center;

            .kw-plus {
                font-size: 10px;
            }
        }
    }
}

.footer-section__manage {
    padding: 2px;
    display: flex;
    overflow: hidden;
    flex-flow: column nowrap;

    .button-component_icon {
        margin-right: 7px;
        @media (max-width: 959px) {
            display: none;
        }
    }

    button {
        &:focus {
            box-shadow: none;
        }
    }

    .reset-limit {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 10px;
        color: kw-color(kw-blue, 1);
        cursor: pointer;
        margin-bottom: 10px;

        b {
            font-weight: 700;
        }
    }
}

.progress-section {
    width: 100%;
    display: flex;
    color: #63697b;
    flex-wrap: wrap;
    min-width: 85px;
    max-width: 285px;
    margin-bottom: 1px;
    justify-content: space-between;
}
</style>
